import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"
import { Link } from "gatsby"

const Depressie = () => (
  <DepressieWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Angsten</h1>
    </Flex>
    <Box p={3}>
      <StyledLink to="/specialisaties"> Terug</StyledLink>
      <TextBlock className="slanted centered">
        Je bent niet je gedachten, en je bent dus ook niet je angsten.
      </TextBlock>
      <TextBlock>Ieder mens heeft verschillende basisemoties:</TextBlock>
      <TextBlock>De 5 B's:</TextBlock>
      <ol>
        <li>boosheid/woede</li>
        <li>blijdschap/vreugde</li>
        <li>bedroefd/verdriet</li>
        <li>bang/angst</li>
        <li>beschaamd</li>
        <TextBlock>
          <i>En verder nog:</i>
        </TextBlock>
        <li>walging/afschuw</li>
        <li>verrassing/verbazing</li>
        <li>liefde</li>
      </ol>
      <TextBlock>
        Emoties zijn subjectieve gevoelens, het is een innerlijke beleving. Ze
        worden door een bepaalde situatie opgeroepen of kunnen spontaan
        optreden. Emoties gaan samen met lichamelijke reacties,
        gezichtsuitdrukkingen en gedrag.
      </TextBlock>
      <h2>Wat mag je van de therapie verwachten?</h2>
      <TextBlock>
        We zijn allemaal uniek en elke therapie wordt dan ook op jouw doelen en
        wensen afgesteld. Enkele resultaten kunnen zijn:
      </TextBlock>
      <ul>
        <li>
          Jouw persoonlijk doel concreet maken en met kleine stapjes
          verwezenlijken.
        </li>
        <li>Je hebt geleerd om je gedachten te observeren en te managen.</li>
        <li>Je hebt meer zelfvertrouwen.</li>
        <li>Je durft tot actie over te gaan.</li>
        <li>Je kan genieten van jezelf en van anderen.</li>
        <li>Je hebt technieken geleerd om acute angst het hoofd te bieden.</li>
      </ul>
      <TextBlock>
        Op zich zijn emoties, ook negatieve emoties, een dankbaar geschenk voor
        de mensheid. Het beschermt hem/haar immers tegen gevaar. Het is een
        natuurlijke reactie op mogelijks gevaar. De{" "}
        <b>zogenoemde vecht-, vlucht- of verstarreactie</b> is een
        verdedigingsmechanisme dat bij mensen en dieren optreedt als er een
        acuut gevaar dreigt. Deze reactie wordt in gang gezet door ons autonoom
        zenuwstelsel. Het begint met plotse hevige stress en angst. Als gevolg
        gaat ons lichaam de hormonen adrenaline en cortisol produceren. Ons
        lichaam wordt voorbereid om in gevecht te gaan of om te vluchten. Som is
        verstarren of verstijven de veiligste optie, maar deze reactie komt
        minder voor. Het gebeurt allemaal als 'vanzelf'. Het spreekt voor zich
        dat het 'autonoom' zenuwstelsel werkt zonder dat we er vat op hebben.
        Nogmaals, in vele omstandigheden is dat maar goed ook voor onze
        veiligheid. Als het gevaar geweken is, produceert het lichaam andere
        hormonen, namelijk endorfines en dopamine om de balans in ons lichaam
        terug te herstellen. Het parasympatisch zenuwstelsel, een deel van het
        autonoom zenuwstelsel, komt in actie.
      </TextBlock>

      <TextBlock>
        Het probleem stelt zich pas als de angst groter wordt dan het objectieve
        gevaar. We spreken dan van <b>irreële angst</b>. Irreële angsten kunnen
        zodanig je leven beïnvloeden dat het je dagelijks functioneren
        verstoord. We spreken dan van een angststoornis. Het gaat over gedachten
        die je koppelt aan angst. Angst komt bij zeer veel mensen voor, je bent
        dus in geen geval de enigste die hier last heeft. Alsof ongewenste angst
        op zich nog niet vervelend genoeg is, heeft het ook nog eens negatieve
        gevolgen op korte en lange termijn:
      </TextBlock>
      <TextBlock>
        Emotioneel gezien maakt het je onzeker en gefrustreerd. Frustratie kan
        dan weer leiden tot boosheid en verdriet. Het is moeilijk om je
        zelfvertrouwen en zelfwaarde in stand te houden. Angst kan je behoorlijk
        belemmeren in het leven van je dromen en je doelen. In veel gevallen
        wordt je sociaal netwerk ook kleiner. Je gaat situaties uit de weg. Deze
        vermijding en ontsnapping kan ertoe leiden dat je je eigen behoeftes en
        verwachtingen teniet doet.
      </TextBlock>
      <TextBlock>
        Op lichamelijk vlak is de kans groot dat je versnelde hartkloppingen
        voelt, een licht gevoel in je hoofd ervaart, je hebt het benauwd door
        een <b>gejaagde ademhaling, je trilt en transpireert</b> en nog zoveel
        meer. Het zijn de gevolgen van het orthosympatisch zenuwstelsel (een
        deel van het autonoom zenuwstelstel) in actie. Deze lichamelijke
        reacties kunnen op zich geen kwaad, maar kunnen wel als angstaanjagend
        ervaren worden. We zitten dan in een vicieuze cirkel: iets heeft je
        angstig gemaakt, je autonoom zenuwstelsel komt in actie, je ervaart
        lichaamssymptomen die je nog angstiger maken en je orthosympatisch
        zenuwstelsel blijft in actie. Er is geen terugkeer naar lichamelijke
        balans (parasympatisch zenuwstelsel) en dat is zeer vermoeiend! Men
        noemt dit verschijnsel 'angst voor de angst'. Het vergroot je eigen
        kwetsbaarheid.
      </TextBlock>
      <TextBlock>
        Met inzichten, technieken en oefeningen kunnen we de angst aanpakken. Op
        die manier doorbreken we ook de 'angst voor de angst'. Daarmee vergroten
        we jouw zelfvertrouwen en met je zelfvertrouwen vergroten we jouw wereld
        en kan je je doelen en dromen verwezelijken. Als je dit wenst, kan de
        ademtechniek hartcoherentie je hierbij helpen. Meer info kan je op deze
        website terugvinden.
      </TextBlock>
      <TextBlock>
        Herken je jezelf hier voor een deel of volledig in? Zo ja, dan nodig ik
        je uit om via 'contact' een afspraak te maken.
      </TextBlock>
    </Box>
  </DepressieWrapper>
)

export default Depressie

const DepressieWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #00628f;
  display: block;
  margin-bottom: 25px;
  font-size: 1.1em;
`
