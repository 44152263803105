import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Angsten from "../modules/specialisaties/Angsten"

const AngstenPage = () => (
  <GlobalCollection>
    <Angsten />
  </GlobalCollection>
)

export default AngstenPage
